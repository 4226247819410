import { useState, useEffect } from 'react'
import styles from './ToolBar.module.css'
import ToolButton from '../ToolButton/ToolButton'
import useTwilio from '../../hooks/useTwilio'

const ToolBar = ({ room, setRoom, video, audio, setVideo, setAudio }) => {

    const [fs, setFs] = useState(document.fullscreenElement !== null)
    const { toggleVideo, toggleAudio } = useTwilio(room, setRoom)

    useEffect(() => {
        
        const onFullScreenChange = () => {
            setFs(document.fullscreenElement !== null)
        }
        
        document.addEventListener('fullscreenchange', onFullScreenChange)

        return () => {document.removeEventListener('fullscreenchange', onFullScreenChange)}
    }, [setFs])

    const onToggleFullScreen = () => {
        if (!fs){
            let element = document.getElementById("live-stream")
            if (element.requestFullscreen){
                element.requestFullscreen()
            }
        } else {
            document.exitFullscreen()
        }
    }

    const onToggleCam = () => {
        let newState = !video
        toggleVideo(newState)
        setVideo(newState)
    }

    const onToggleMic = () => {
        let newState = !audio
        toggleAudio(newState)
        setAudio(newState)
    }

    return (
        <div className={styles.container}>
            <ToolButton icon='mic' on={audio} onClick={onToggleMic}/>
            <ToolButton icon='cam' on={video} onClick={onToggleCam}/>
            <ToolButton icon='fs' on={!fs} onClick={onToggleFullScreen}/>
        </div>
    )
}

export default ToolBar