// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SlideShow_container__1iILO {\n    position: absolute;\n    width: 100%;\n    height: 100vh;\n    background-size: cover;\n    background-position: center center;\n    opacity: 0;\n}", "",{"version":3,"sources":["webpack://src/Components/SlideShow/SlideShow.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kCAAkC;IAClC,UAAU;AACd","sourcesContent":[".container {\n    position: absolute;\n    width: 100%;\n    height: 100vh;\n    background-size: cover;\n    background-position: center center;\n    opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SlideShow_container__1iILO"
};
export default ___CSS_LOADER_EXPORT___;
