import React, { useEffect, useState, useRef } from 'react'
import styles from './UserVideo.module.css'
import useTwilio from '../../hooks/useTwilio'
import { motion } from 'framer-motion'
import cx from "classnames"
import camOffIcon from './icons/camoff@2x.png'
import micOffIcon from './icons/micoff@2x.png'

const variants = {
    off: {
        opacity: 0
    },
    on: {
        opacity: 1
    }
}

const UserVideo = ({ identity, videoDevice, room, setRoom, audio, video }) => {

    const [display, setDisplay] = useState("off")
    const { createLocalPreview } = useTwilio(room, setRoom)
    const refVideo = useRef(null)

    useEffect(() => {
        createLocalPreview(videoDevice, onComplete)
    }, [])

    const onComplete = (stream) => {
        refVideo.current.srcObject = stream
        setDisplay("on")
    }

    return (
        <motion.div className={styles.container} variants={variants} animate={display}>
            <video className={cx(!video && styles.videoOff)} autoPlay ref={refVideo} muted></video>
            <p className={styles.identity}>{identity}</p>
            {!video && <img src={camOffIcon} className={styles.camOff}/>}
            {!audio && <img src={micOffIcon} className={styles.micOff}/>}
        </motion.div>
    )
}

export default UserVideo