// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Message_container__3THxA {\n    position: relative;\n    background-color: white;\n    font-family: \"ABChanel Corpo\";\n    font-size: 1.5rem;\n    padding: 5rem;\n    padding-top: 8rem;\n    padding-bottom: 8rem;\n    opacity: 0;\n}\n\n.Message_container__3THxA p:first-child{\n    margin-bottom: 3rem;\n}", "",{"version":3,"sources":["webpack://src/Components/Message/Message.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,6BAA6B;IAC7B,iBAAiB;IACjB,aAAa;IACb,iBAAiB;IACjB,oBAAoB;IACpB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".container {\n    position: relative;\n    background-color: white;\n    font-family: \"ABChanel Corpo\";\n    font-size: 1.5rem;\n    padding: 5rem;\n    padding-top: 8rem;\n    padding-bottom: 8rem;\n    opacity: 0;\n}\n\n.container p:first-child{\n    margin-bottom: 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Message_container__3THxA"
};
export default ___CSS_LOADER_EXPORT___;
