export default {
    red5:{
        protocol: 'wss',
        host: 'www.chanel-live.com',
        port: 443,
        app: 'live',
        streamName: 'chanel',
        mediaElementId: 'video-container',
    },
    urls:{
        tokenUrl: (identity) => process.env.REACT_APP_TOKEN_ENV == "PRODUCTION" ? `https://token-service-3179-dev.twil.io/token?identity=${identity}` : `http://localhost:3000/token?identity=${identity}`
    },
    homePhotos:[
        'https://remote-sales.imgix.net/chanel/settings_0.png?fm=jpg',
        'https://remote-sales.imgix.net/chanel/settings_1.png?fm=jpg',
        'https://remote-sales.imgix.net/chanel/settings_2.png?fm=jpg',
        'https://remote-sales.imgix.net/chanel/settings_3.png?fm=jpg'
    ],
    locale:{
        your_name: "Your name...",
        room_expired: ["Impossible to join this room.", "Please, contact your personnal assistant."]
    }
}