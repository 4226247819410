import React, { useState, useEffect } from 'react'
import cs from 'classnames'
import styles from './SlideShow.module.css'
import { motion } from 'framer-motion'

const SlideShowConfig = {
    duration: 10000,
    fadeDuration: 1.0
}

const variants = {
    off: { opacity: 0, transition:{duration: SlideShowConfig.fadeDuration}},
    on: { opacity: 1, transition:{duration: SlideShowConfig.fadeDuration}}
}

const SlideShow = ({ photos }) => {

    const [current, setCurrent] = useState(0)
    const [animation, setAnimation] = useState('on')

    useEffect(() => {
        const itv = setInterval(() => {
            nextPhoto()
        }, SlideShowConfig.duration)

        return () => {
            clearInterval(itv)
        }
    }, [])

    const nextPhoto = () => {
        setAnimation('off')
    }

    const backgroundURL = () => {
        return {
            backgroundImage:`url(${photos[current]})`
        }
    }

    const animationComplete = (value) => {
        if (value == 'off'){
            let nextIndex = current + 1
            nextIndex = nextIndex % photos.length
            setCurrent(nextIndex)
            setAnimation('on')
        }
    }

    return (
        <motion.div className={cs(styles.container)} style={backgroundURL()} variants={variants} animate={animation} onAnimationComplete={animationComplete}>
        </motion.div>
    )
}

export default SlideShow