// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LiveFeed_container__3FkTd {\n    width: 100%;\n    height: 100vh;\n    position:relative;\n    opacity: 0;\n}\n\n.LiveFeed_container__3FkTd video {\n    width: 100%;\n    height: 100vh;\n    object-fit: cover;\n    display: block;\n}", "",{"version":3,"sources":["webpack://src/Components/LiveFeed/LiveFeed.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".container {\n    width: 100%;\n    height: 100vh;\n    position:relative;\n    opacity: 0;\n}\n\n.container video {\n    width: 100%;\n    height: 100vh;\n    object-fit: cover;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LiveFeed_container__3FkTd"
};
export default ___CSS_LOADER_EXPORT___;
