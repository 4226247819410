import React from 'react'
import styles from './Message.module.css'
import { motion } from 'framer-motion'

const variants = {
    on: {
        opacity: 1,
        transition: { duration: 1 }
    }
}

const Message = ({ message }) => {
    return (
        <motion.div className={styles.container} variants={variants} animate='on'>
            {
                message.map(line => {
                    return (
                        <p key={line}>{line}</p>
                    )
                })
            }
        </motion.div>
    )
}

export default Message