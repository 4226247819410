// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolBar_container__1E15c {\n    height: 6rem;\n    min-width: 6rem;\n    transform: translate(-50%, 0%);\n    border-radius: 0.6rem;\n    -webkit-backdrop-filter: brightness(40%) blur(8px);\n            backdrop-filter: brightness(40%) blur(8px);\n    display: flex;\n    align-items: center;\n    position: absolute;\n    left:50%;\n    bottom: 3rem;\n}", "",{"version":3,"sources":["webpack://src/Components/ToolBar/ToolBar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,8BAA8B;IAC9B,qBAAqB;IACrB,kDAA0C;YAA1C,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,YAAY;AAChB","sourcesContent":[".container {\n    height: 6rem;\n    min-width: 6rem;\n    transform: translate(-50%, 0%);\n    border-radius: 0.6rem;\n    backdrop-filter: brightness(40%) blur(8px);\n    display: flex;\n    align-items: center;\n    position: absolute;\n    left:50%;\n    bottom: 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ToolBar_container__1E15c"
};
export default ___CSS_LOADER_EXPORT___;
