import React, { useEffect, useState, useRef } from 'react'
import styles from './CameraSelector.module.css'
import cx from 'classnames'
import { motion } from 'framer-motion'
import config from '../../config'

const variants = {
    off:{
        opacity: 0,
        transition:{
            duration: 1
        }
    },
    on: {
        opacity: 1,
        transition:{
            delay: 1,
            duration: 1
        }
    }
}

const CameraSelector = ({ onJoin }) => {

    const [videoDevices, setVideoDevices] = useState([])
    const [audioDevices, setAudioDevices] = useState([])
    const [identity, setIdentity] = useState('')
    const [videoDevice, setVideoDevice] = useState(null)
    const [audioDevice, setAudioDevice] = useState(null)
    const videoRef = useRef(null)
    const [display, setDisplay] = useState('on')
    const [allowed, setAllowed] = useState(false)

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((res)=>{
            let vDevices = res.filter(d => d.kind === 'videoinput').map(d => ({value: d.deviceId, label:d.label}))
            let aDevices = res.filter(d => d.kind === 'audioinput').map(d => ({value: d.deviceId, label:d.label}))
            setVideoDevices(vDevices)
            setAudioDevices(aDevices)
            setVideoDevice(vDevices[0].value)
            setAudioDevice(aDevices[0].value)
        })
    }, [allowed])

    useEffect(() => {
        getSelectedCamera()
    }, [videoDevice, audioDevice])

    const onDeviceChange = (option, kind) => {
        if (kind === "video"){
            setVideoDevice(option.value)
        }
        if (kind === "audio"){
            setAudioDevice(option.value)
        }
    }

    const onJoinHandle = () => {
        setDisplay('off')
        onJoin(videoDevice, audioDevice, identity)
    }

    const getSelectedCamera = () => {
        if (videoDevice != null && audioDevice != null){
            let constraints = { 
                audio: {
                    deviceId: audioDevice
                },
                video: {
                    deviceId: videoDevice,
                    width: 1920,
                    height: 1080
                }
            }
            navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
                videoRef.current.srcObject = stream
                setAllowed(true)
            }).catch(function() {
            })
        }
    }

    const onIdentityChange = (res) => {
        setIdentity(res.target.value)
    }

    const displayState = () => {
        if (!allowed) {
            return "off"
        } else {
            return display
        }
    }

    return (
        <motion.div className={styles.container} animate={displayState()} variants={variants}>
            <div className={styles.videoPreview}>
                <video ref={videoRef} autoPlay muted/>
            </div>
            <div className={styles.controls}>
                <input value={identity} className={styles.inputLabel} onChange={onIdentityChange} placeholder={config.locale.your_name}/>
                <select onChange={(e)=>{onDeviceChange(e.target, 'video')}} className={styles.select}>
                    {videoDevices.map(v => {
                        return (
                            <option key={v.value} value={v.value} label={v.label}/>
                        )
                    })}
                </select>
                <select onChange={(e) => {onDeviceChange(e.target, 'audio')}} className={styles.select}>
                    {audioDevices.map(a => {
                        return (
                            <option key={a.value} value={a.value} label={a.label}/>
                        )
                    })}
                </select>
 
            </div>
            <div className={cx([styles.joinButton, identity.length == 0 && styles.disabled])} onClick={onJoinHandle}>Join</div>
        </motion.div>
    )
}

export default CameraSelector