
import { useState } from 'react' 
import { connect, createLocalVideoTrack} from 'twilio-video'
import config from '../config'



const Hook = (room, setRoom) => {


    const getToken = async (identity) => {
        const response = await fetch(config.urls.tokenUrl(identity))
        const data = await response.json()
        return data.accessToken
    }

    const connectToRoom = async (roomId, accessToken, videoDevice, audioDevice) => {
        let constraints = {
            video:{
                deviceId: videoDevice,
                width: 1920,
                height: 1080
            },
            audio: {
                deviceId: audioDevice
            }
        }

        let stream = await navigator.mediaDevices.getUserMedia(constraints)
        let room = await connect(accessToken, {
            name: roomId,
            audio: true,
            video: true,
            tracks: stream.getTracks()
        })

        window.onbeforeunload = () => {
            room.disconnect()
        }

        return room
    }

    const join = async (videoDevice, audioDevice, identity, onSuccess, onError) => {
        let accessToken = await getToken(identity)
        let roomId = window.location.href.split("/").pop().toLowerCase()
        let room = await connectToRoom(roomId, accessToken, videoDevice, audioDevice)
        var success = false

        if (room.participants.size > 0){
            let participant = Array.from(room.participants)[0]
            if (participant[1].identity == "chanel-ipad"){
                success = true
            }
        } 
        if (success){
            setRoom(room)
            onSuccess()
        } else {
            onError()
        }
    }

    const toggleVideo = (value) => {
        if (!value){
            room.localParticipant.videoTracks.forEach(track => {
                track.track.disable()
            })
        } else {
            room.localParticipant.videoTracks.forEach(track => {
                track.track.enable()
            })
        }
    }

    const toggleAudio = (value) => {
        if (!value){
            room.localParticipant.audioTracks.forEach(track => {
                track.track.disable()
            })
        } else {
            room.localParticipant.audioTracks.forEach(track => {
                track.track.enable()
            })
        }
    }

    const createLocalPreview = async (videoDevice, onComplete) => {
        const constraints = {
            video: {
                deviceId: videoDevice
            },
            audio: false
        }
        let stream = await navigator.mediaDevices.getUserMedia(constraints)
        onComplete(stream)
    }

    return {
        join,
        getToken,
        connectToRoom,
        createLocalPreview,
        toggleVideo,
        toggleAudio
    }
}

export default Hook