import { useState } from 'react'
import './App.css';
import CameraSelector from './Components/CameraSelector/CameraSelector'
import LiveFeed from './Components/LiveFeed/LiveFeed'
import SlideShow from './Components/SlideShow/SlideShow'
import Message from './Components/Message/Message'
import UserVideo from './Components/UserVideo/UserVideo'
import useTwilio from './hooks/useTwilio'
import config from './config'

const Step = {
  camera: 'camera',
  connection: 'connection',
  loadingLive: 'loadingLive',
  live: 'live',
  message: 'message'
}

function App() {

  const [step, setStep] = useState(Step.camera)
  const [error, setError] = useState(null)
  const [identity, setIdentity] = useState("")
  const [videoDevice, setVideoDevice] = useState(null)
  const [audioDevice, setAudioDevice] = useState(null)
  const [room, setRoom] = useState(null)
  const [video, setVideo] = useState(true)
  const [audio, setAudio] = useState(true)
  const { join } = useTwilio(room, setRoom)

  const onSuccess = () => {
    setStep(Step.loadingLive)
  }

  const onError = () => {
    setStep(Step.message)
    setError(config.locale.room_expired)
  }

  const onJoin = async (videoDevice, audioDevice, identity) => {
    setIdentity(identity)
    setAudioDevice(audioDevice)
    setVideoDevice(videoDevice)
    join(videoDevice, audioDevice, identity, onSuccess, onError)
  }

  const onLiveReady = () => {
    setStep('live')
  }


  return (
    <div className="App">
      {step != Step.live && <SlideShow photos={config.homePhotos}/>}
      {error && <Message message={error}/>}
      {step == Step.camera && <CameraSelector onJoin={onJoin}/>}
      {(step == Step.live || step == Step.loadingLive) && <LiveFeed onLiveReady={onLiveReady} 
                                                                    room={room} 
                                                                    setRoom={setRoom} 
                                                                    audio={audio} 
                                                                    video={video}
                                                                    setAudio={setAudio}
                                                                    setVideo={setVideo}/>}
      {step == Step.live && <UserVideo  identity={identity} 
                                        videoDevice={videoDevice} 
                                        room={room} 
                                        setRoom={setRoom}
                                        video={video}
                                        audio={audio}/>}
    </div>
  );
}

export default App;
