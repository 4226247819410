import React, { useEffect, useState } from 'react'
import styles from './LiveFeed.module.css'
import red5 from '../../hooks/useRed5'
import { motion } from 'framer-motion'
import ToolBar from '../ToolBar/ToolBar'


const variants = {
    off: {
        opacity: 0
    },
    on: {
        opacity: 1,
        transition: {
            duration: 1
        }
    }
}

const LiveFeed = ({ onLiveReady, room, setRoom, video, audio, setVideo, setAudio }) => {

    const { subscribe } = red5()
    const [status, setStatus] = useState('off')

    const onReady = () => {
        setStatus("on")
    }

    const onAnimationComplete = (value) => {
        if (value == "on"){
            onLiveReady()
        }
    }

    useEffect(() => {
        subscribe(onReady)
    }, [])

    return (
        <motion.div className={styles.container} animate={status} variants={variants} onAnimationComplete={onAnimationComplete} id="live-stream">
            <video id='video-container' autoPlay/>
            <ToolBar room={room} setRoom={setRoom} audio={audio} video={video} setAudio={setAudio} setVideo={setVideo}/>
        </motion.div>
    )
}

export default LiveFeed