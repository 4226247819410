import { RTCSubscriber } from 'red5pro-webrtc-sdk'
import config from '../config'

const Hook = () => {

    const subscribe = (onReady) => {
        let subscriber = new RTCSubscriber()
        subscriber.init(config.red5).then(() => {
            return subscriber.subscribe()
        }).then(() => {
        })
        subscriber.on('*', (evt) => {
            if (evt.type == "Subscribe.Playback.Change"){
                if (evt._data.state == "Playback.AVAILABLE"){
                    onReady()
                }
            }
        })
    }
    
    return {
        subscribe
    }   
}

export default Hook