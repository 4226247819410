// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolButton_container__27YMK {\n    width: 4rem;\n    height: 4rem;\n    margin-left: 2rem;\n    margin-right: 2rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.ToolButton_container__27YMK:hover {\n    cursor: pointer;\n}\n\n.ToolButton_icon__3Z2Sa {\n    width: 3rem;\n    height: 3rem;\n}", "",{"version":3,"sources":["webpack://src/Components/ToolButton/ToolButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    width: 4rem;\n    height: 4rem;\n    margin-left: 2rem;\n    margin-right: 2rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.container:hover {\n    cursor: pointer;\n}\n\n.icon {\n    width: 3rem;\n    height: 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ToolButton_container__27YMK",
	"icon": "ToolButton_icon__3Z2Sa"
};
export default ___CSS_LOADER_EXPORT___;
